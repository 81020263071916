.error {
    border: 1px solid #F44336;
}

.error > input{
    border: none;
}
.date {
    outline: none;
    font-size: 16px;
    font-weight: 400;
    color: rgba(0,0,0,0.87);
    border: none;
    width: 100%;
    padding: 9px 0 5px;
    border-bottom: 1px solid #949494
}
.date > input{
  border: none;

}

.date:read-only {
  color:rgba(0,0,0,0.38);
}

.react-datepicker-popper {
    z-index: 1400;
}

.react-datepicker-wrapper {
    display: inline;
    font-size: 15px;
}

.react-datepicker__day--highlighted {
    background-color: inherit;
    color: #F44336;
}

.react-datepicker__day--highlighted:hover {
    border-radius: 0.3rem;
    background-color: #f0f0f0;
}
.react-datepicker__day--highlighted--selected {
  border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker__day--highlighted---selected{
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}

.react-datepicker__day--selected{
    border-radius: 0.3rem;
    background-color: #216ba5;
    color: #fff;
}
.react-datepicker__day--selected:hover {
  background-color: #1d5d90;
}
