*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  margin: 0;
  padding: 0;
  font-family: "Avenir Next Cyr";
  color: #333333;
}

body {
  margin: 0;
  padding: 0;
}